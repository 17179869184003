import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextFieldProps } from '@mui/material';
import { NumberField } from 'components/NumberField/NumberField';
import { useBaseUnitsFromCategoryId } from 'features/ProjectComponents/hooks/useBaseUnitsFromCategoryId';
import { useEffect } from 'react';
import { BaseUnitIconsMap } from 'utils/BaseUnitIcons';

type BaseUnitFieldProps = {
  constructionCategory: number | null;
  onChange: (value?: number) => void;
} & Omit<TextFieldProps, 'onChange' | 'prefix'>;
export const BaseUnitField = (props: BaseUnitFieldProps) => {
  const { baseUnits } = useBaseUnitsFromCategoryId(
    props.constructionCategory ?? undefined,
  );

  useEffect(() => {
    if (!baseUnits) {
      props.onChange(0);
    }
  }, [baseUnits, props.onChange]);

  return baseUnits ? (
    <NumberField
      label={`Number of ${baseUnits.name}`}
      prefix={<FontAwesomeIcon icon={BaseUnitIconsMap[baseUnits.key]} />}
      suffix={baseUnits.name}
      placeholder="0"
      {...props}
    />
  ) : null;
};

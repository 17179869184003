import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { queryKeys } from 'utils/reactQuery';
import {
  AnalysisVariant,
  AnalysisVariantCosts,
  AnalysisVariantSummary,
  ConceptAnalysis,
} from '../type';
import { ProjectComparison, ProjectComparisonComponent } from 'types/Calibrate';
import { useTheme } from '@mui/material';
import { JSONValue } from 'types/Common';
import { replaceProjectIdPath, useGetProjectHomePage } from 'hooks/navigate';
import { useSwitchProject } from 'features/Projects/hook/project';

export function useConceptLabAnalysis() {
  const conceptAnalysisQuery = useQuery({
    queryKey: queryKeys.conceptLab,
    queryFn: ({ signal }) => {
      const endPoint = Resources.CONCEPT_ANALYSIS_LIST;
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ConceptAnalysis[],
      );
    },
    refetchOnWindowFocus: false,
  });
  return { conceptAnalysisQuery };
}

export function useConceptLabAnalysisById({ id }: { id: number }) {
  const conceptAnalysisByIdQuery = useQuery({
    queryKey: queryKeys.conceptAnalysis(id).details,
    queryFn: ({ signal }) => {
      const endPoint = Resources.CONCEPT_ANALYSIS_BY_ID.replace(
        '<int:analysis_pk>',
        id.toString(),
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ConceptAnalysis,
      );
    },
    refetchOnWindowFocus: false,
  });
  return { conceptAnalysisByIdQuery };
}

export const useSaveConceptLabAnalysis = () => {
  const queryClient = useQueryClient();

  const saveConceptLabAnalysisMutation = useMutation({
    mutationFn: ({ analysis }: { analysis: Partial<ConceptAnalysis> }) => {
      const endPoint = analysis.id
        ? Resources.CONCEPT_ANALYSIS_BY_ID.replace(
            '<int:analysis_pk>',
            analysis.id.toString(),
          )
        : Resources.CONCEPT_ANALYSIS_LIST;
      return ApiService.post(endPoint, analysis).then((res) => res.data);
    },
    onSettled: (_, _error) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptLab,
      });
    },
  });

  return {
    saveConceptLabAnalysisMutation,
  };
};

export const useDeleteConceptLabAnalysis = () => {
  const queryClient = useQueryClient();

  const deleteConceptLabAnalysisMutation = useMutation({
    mutationFn: ({ analysisId }: { analysisId: number }) => {
      const endPoint = Resources.CONCEPT_ANALYSIS_BY_ID.replace(
        '<int:analysis_pk>',
        analysisId.toString(),
      );
      return ApiService.delete(endPoint).then((res) => res.data);
    },
    onSettled: (_, _error) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptLab,
      });
    },
  });

  return {
    deleteConceptLabAnalysisMutation,
  };
};

export const useConceptLabVariantById = ({ id }: { id: number | null }) => {
  const conceptLabVariantByIdQuery = useQuery({
    queryKey: queryKeys.conceptAnalysisVariant(id),
    queryFn: ({ signal }) => {
      const endPoint = Resources.CONCEPT_VARIANTS_BY_ID.replace(
        '<int:variant_pk>',
        (id ?? '').toString(),
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as AnalysisVariantCosts,
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
  return { conceptLabVariantByIdQuery };
};

export const useSaveConceptLabVariant = () => {
  const queryClient = useQueryClient();

  const saveConceptLabVariantMutation = useMutation({
    mutationFn: ({ variant }: { variant: Partial<AnalysisVariant> }) => {
      const endPoint = variant.id
        ? Resources.CONCEPT_VARIANTS_BY_ID.replace(
            '<int:variant_pk>',
            variant.id.toString(),
          )
        : Resources.CONCEPT_VARIANTS_LIST;

      const method = variant.id ? ApiService.put : ApiService.post;

      return method(endPoint, variant).then((res) => res.data);
    },
    onMutate: async ({ variant }) => {
      const queryKey = queryKeys.conceptAnalysisVariant(variant.id);
      await queryClient.cancelQueries({ queryKey });

      const previousVariantData = queryClient.getQueryData(
        queryKey,
      ) as AnalysisVariantCosts;
      if (previousVariantData) {
        queryClient.setQueryData(queryKey, {
          ...previousVariantData,
          ...variant,
        });
      }

      return { previousVariantData };
    },
    onError: (error, { variant }, context) => {
      if (context?.previousVariantData) {
        queryClient.setQueryData(
          queryKeys.conceptAnalysisVariant(variant.id),
          context.previousVariantData,
        );
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: (_, _error, { variant }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptAnalysis(variant.analysis).details,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptLab,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptAnalysisVariant(variant.id),
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptAnalysisVariantSummary(variant.id),
      });
    },
  });

  return {
    saveConceptLabVariantMutation,
  };
};

export const useDeleteConceptLabVariant = () => {
  const queryClient = useQueryClient();

  const deleteConceptLabVariantMutation = useMutation({
    mutationFn: ({ variant }: { variant: AnalysisVariant }) => {
      const endPoint = Resources.CONCEPT_VARIANTS_BY_ID.replace(
        '<int:variant_pk>',
        variant.id.toString(),
      );
      return ApiService.delete(endPoint).then((res) => res.data);
    },
    onSettled: (_, _error, { variant }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptAnalysis(variant.analysis).details,
      });
    },
  });

  return {
    deleteConceptLabVariantMutation,
  };
};

export const useConceptLabVariantSummary = ({ id }: { id: number | null }) => {
  const theme = useTheme();
  const conceptLabVariantSummaryQuery = useQuery({
    queryKey: queryKeys.conceptAnalysisVariantSummary(id),
    queryFn: ({ signal }) => {
      const endPoint = Resources.CONCEPT_VARIANT_SUMMARY_BY_ID.replace(
        '<int:variant_pk>',
        (id ?? '').toString(),
      );
      return ApiService.get(endPoint, { signal }).then((res) => {
        const projects = (res.data?.projects ?? []).map(
          (project: ProjectComparison, index: number) => ({
            ...project,
            components: res.data.components.filter(
              (c: ProjectComparisonComponent) => c.project === project.id,
            ),
            color: theme.palette.other.getProjectComparisonColor(index),
          }),
        ) as ProjectComparison[];

        return {
          ...(res.data ?? {}),
          projects,
        } as AnalysisVariantSummary;
      });
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
  return { conceptLabVariantSummaryQuery };
};

export const usePromoteConceptToProject = () => {
  const queryClient = useQueryClient();
  const { switchToProject } = useSwitchProject();
  const { getProjectHomePageStep } = useGetProjectHomePage();

  const promoteConceptToProjectMutation = useMutation({
    mutationFn: ({
      analysisId,
      projectData,
    }: {
      analysisId: number;
      projectData: JSONValue;
    }) => {
      return ApiService.post(
        Resources.CONCEPT_LAB_ANALYSIS_PROMOTE.replace(
          '<int:analysis_pk>',
          analysisId.toString(),
        ),
        projectData,
      ).then((res) => res.data);
    },
    onSettled: (data, _error) => {
      switchToProject(data.id, {
        to: replaceProjectIdPath(getProjectHomePageStep(data), data.id),
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptLab,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.projectsLean,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.projects,
      });
    },
  });

  return {
    promoteConceptToProjectMutation,
  };
};

import { faBuilding, faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, alpha, CircularProgress, Stack, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { convertBytesToMegaBytes, joinSx } from 'utils/helpers';
import { FileWithPath, FileError, Accept, useDropzone } from 'react-dropzone';
import { ImageContainer } from './ImageContainer';
import { MAX_FILENAME_LENGTH } from 'utils/constants';
import { AlertBanner } from 'components/AlertBanner/AlertBanner';
import log from 'loglevel';
interface FileWithPathRejection {
  file: FileWithPath;
  errors: FileError[];
}

type ImageUploaderProps = {
  onDrop: (picture: File) => void;
  picture?: string | null;
  label?: string;
  uploadIcon?: ReactNode;
  disabled?: boolean;
  multiple?: boolean;
  maxFileSize?: number;
  accept?: Accept;
  loading?: boolean;
  onDelete?: () => void;
};

export const ImageUploader = (props: ImageUploaderProps) => {
  const theme = useTheme();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [isImageLoading, setIsImageLoading] = useState(Boolean(props.picture));

  function sizeAndNameValidator(file: FileWithPath) {
    if (convertBytesToMegaBytes(file.size) > (props.maxFileSize || 20)) {
      return {
        code: 'file-too-large',
        message: `Files is larger than ${props.maxFileSize || 20} MB`,
      };
    }
    if (file.name.length > MAX_FILENAME_LENGTH) {
      return {
        code: 'file-name-too-large',
        message: `File name is larger than ${MAX_FILENAME_LENGTH} characters`,
      };
    }

    return null;
  }

  const handleOnDrop = (acceptedFiles: FileWithPath[]) => {
    if (acceptedFiles.length) {
      setUploadedFile(acceptedFiles.at(0));
      props?.onDrop(acceptedFiles.at(0) as File);
    }
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    multiple: props.multiple,
    onDrop: handleOnDrop,
    disabled: props.disabled || props.loading || isImageLoading,
    accept: props.accept ?? {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
    useFsAccessApi: false,
    validator: sizeAndNameValidator,
  });

  const fileRejectionItems = fileRejections.map(
    ({ file, errors }: FileWithPathRejection) => {
      log.error(
        `${file.path} - ${Math.round(convertBytesToMegaBytes(file.size) * 100) / 100} MB`,
      );
      errors.forEach((error) => log.error(`\t ${error.code}: ${error.message}`));
      return (
        <Box key={file.path} sx={{ mt: 1 }}>
          {errors.map((e) => (
            <AlertBanner severity="error" key={e.code} sx={{ mb: 1 }}>
              {e.message}
            </AlertBanner>
          ))}
        </Box>
      );
    },
  );

  const isLoading = isImageLoading || props.loading;

  return (
    <Box
      {...getRootProps()}
      sx={joinSx(
        { position: 'relative', width: '100%' },
        !props.onDelete
          ? {
              '&:hover': {
                '& .project-image-container': {
                  zIndex: 50,
                },
              },
            }
          : null,
      )}
      className="dropzone-root-component"
    >
      <input {...getInputProps()} />
      <Stack
        sx={(theme) => ({
          backgroundColor: alpha(theme.palette.grey[50], 0.65),
          backdropFilter: 'blur(5px)',
          margin: 'auto',
          cursor: 'pointer',
          width: '100%',
          height: '144px',
          padding: theme.spacing(2),
          border: `1px dashed ${theme.palette.grey[200]}`,
          borderRadius: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          color: props.disabled ? 'grey.300' : 'grey.500',
          zIndex: isLoading ? 300 : 100,
          position: 'relative',
        })}
      >
        <Box
          sx={{
            fontSize: '2.5rem',
            color: props.disabled ? 'grey.300' : 'primary.main',
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            props.uploadIcon || <FontAwesomeIcon icon={faBuilding} />
          )}
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          {!isLoading && (
            <Typography
              variant="textDefaultSemiBold"
              sx={{ textAlign: 'center', color: 'grey.700' }}
            >
              {props.label ? (
                props.label
              ) : (
                <>
                  Drag and drop <br />
                  or{' '}
                  <Box component="span" sx={{ color: 'blue.700' }}>
                    choose Image
                  </Box>
                </>
              )}
            </Typography>
          )}
        </Box>
      </Stack>
      {props.picture && props.onDelete ? (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            bottom: '0px',
            zIndex: 1100,
            '&:hover': {
              backgroundColor: alpha(theme.palette.grey[50], 0.65),
            },
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            component={FontAwesomeIcon}
            icon={faClose}
            onClick={(e) => {
              e.stopPropagation();
              props.onDelete?.();
            }}
            sx={{ p: 1, cursor: 'pointer' }}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          zIndex: 200,
        }}
        className="project-image-container"
      >
        <ImageContainer
          key={props.picture}
          picture={props.picture}
          file={uploadedFile}
          onImageLoad={() => {
            setIsImageLoading(false);
          }}
        />
      </Box>
      {fileRejectionItems}
    </Box>
  );
};

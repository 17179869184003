import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'utils/reactQuery';
import { Resources } from 'api/Resources';
import { ApiService } from 'api/ApiService';
import {
  DashboardMembership,
  DEFAULT_PROJECT_DASHBOARD_PORTFOLIO,
  ProjectDashboard,
  ProjectDashboardDetailsId,
} from 'types/Dashboard';
import log from 'loglevel';
import { EditingProjectDashboard } from 'features/project-dashboard/helpers';
import { useMemo } from 'react';
import usePermissions from 'features/Auth/hook/usePermissions';
import { omit, sortBy } from 'lodash-es';

export const isNewProjectDashboardByDetailsId = (
  id: ProjectDashboardDetailsId | undefined,
) => id === 'new';
export function useProjectDashboards() {
  const { hasPerm, Permissions } = usePermissions();
  const projectDashboardsQuery = useQuery({
    queryKey: queryKeys.projectDashboards,
    queryFn: ({ signal }) => {
      const endPoint = Resources.PROJECT_DASHBOARDS;
      return ApiService.get(endPoint, { signal }).then((res) =>
        sortBy(res.data as ProjectDashboard[], 'name'),
      );
    },
    refetchOnWindowFocus: false,
  });

  const hasPortfolioAccess = hasPerm(Permissions.PORTFOLIO_ACCESS);
  const data = useMemo(() => {
    if (!projectDashboardsQuery.data || !hasPortfolioAccess)
      return projectDashboardsQuery.data;

    const result: ProjectDashboard[] = [
      {
        id: DEFAULT_PROJECT_DASHBOARD_PORTFOLIO,
        name: 'Portfolio',
        type: 'INTERNAL',
        description: '',
        project_groups: [],
        related_projects: [],
        created_at: '',
        users: [],
      },
    ];

    return [...result, ...projectDashboardsQuery.data];
  }, [hasPortfolioAccess, projectDashboardsQuery.data]);

  return { projectDashboardsQuery: { ...projectDashboardsQuery, data } };
}

export const useProjectDashboard = ({
  projectDashboardId,
}: {
  projectDashboardId: ProjectDashboardDetailsId | undefined;
}) => {
  const queryClient = useQueryClient();
  const projectDashboardQuery = useQuery({
    queryKey: queryKeys.projectDashboard(projectDashboardId).details,
    queryFn: ({ signal }) => {
      if (!projectDashboardId) throw Error('Project dashboard not set');
      const endPoint = Resources.PROJECT_DASHBOARD_BY_ID.replace(
        '<int:pk>',
        projectDashboardId!.toString(),
      );

      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ProjectDashboard,
      );
    },
    enabled: !!projectDashboardId && typeof projectDashboardId === 'number',
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    initialData: () => {
      // We try to find items fetched from previous requests
      const cachedItems = queryClient.getQueryData(queryKeys.projectDashboards) as
        | ProjectDashboard[]
        | null;
      return cachedItems?.find((item) => item.id === projectDashboardId);
    },
  });

  return {
    projectDashboardQuery,
  };
};

export function useSaveProjectDashboard() {
  const queryKey = queryKeys.projectDashboards;

  const queryClient = useQueryClient();
  const saveProjectDashboardMutation = useMutation({
    mutationFn: async (updatedProjectDashboard: EditingProjectDashboard) => {
      const updated = omit(updatedProjectDashboard, [
        'memberships',
        'users',
        'related_projects',
        'projects',
        'project_groups',
        'picture',
        'pictureFile',
      ] as (keyof EditingProjectDashboard)[]);
      let result;
      if (updatedProjectDashboard.id) {
        const endPoint = Resources.PROJECT_DASHBOARD_BY_ID.replace(
          '<int:pk>',
          updatedProjectDashboard.id.toString(),
        );
        result = await ApiService.patch(endPoint, updated).then(
          (res) => res.data as ProjectDashboard,
        );
      } else {
        const endPoint = Resources.PROJECT_DASHBOARDS;
        result = await ApiService.post(endPoint, updated).then(
          (res) => res.data as ProjectDashboard,
        );
      }
      const membershipsEndpoint = Resources.PROJECT_DASHBOARD_MEMBERSHIPS.replace(
        '<int:dashboard_pk>',
        result.id.toString(),
      );
      const updatedMemberships = updatedProjectDashboard.users?.map((u) => {
        const membership = updatedProjectDashboard.memberships?.find(
          (m) => m && m.user_id === u.id,
        );

        return {
          id: membership?.id,
          user_id: u.id,
          permission_ids: membership?.permission_ids || [],
        } as DashboardMembership;
      });
      await ApiService.post(membershipsEndpoint, updatedMemberships).then(
        (res) => res.data,
      );
      if (updatedProjectDashboard.pictureFile) {
        const imageEndpoint = Resources.PROJECT_DASHBOARD_IMAGE.replace(
          '<int:dashboard_pk>',
          result.id.toString(),
        );

        const form = new FormData();
        const file = updatedProjectDashboard.pictureFile;

        form.append('picture', file);

        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        result = ApiService.post(imageEndpoint, form, config).then(
          (res) => res.data as ProjectDashboard,
        );
      }

      return result;
    },
    onMutate: async (newValue) => {
      await queryClient.cancelQueries({ queryKey });
      const previousGroups: ProjectDashboard[] | undefined =
        queryClient.getQueryData(queryKey);
      if (previousGroups) {
        queryClient.setQueryData(queryKey, (oldDashboards: ProjectDashboard[]) => {
          if (!newValue.id) {
            return [...(oldDashboards || []), { ...newValue, id: 0 }];
          }
          return [
            ...(oldDashboards || []).map((oldDashboard) =>
              oldDashboard.id === newValue.id ? newValue : oldDashboard,
            ),
          ];
        });
      }

      return previousGroups;
    },
    onError: (error, _newValue, previousGroups) => {
      if (previousGroups) {
        queryClient.setQueryData(queryKey, previousGroups);
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: (data, _error) => {
      queryClient
        .invalidateQueries({
          queryKey,
        })
        .then(() => {
          if (data?.id) {
            queryClient.removeQueries({
              queryKey: queryKeys.projectDashboard(data.id).details,
            });
          }
        });
    },
  });
  return { saveProjectDashboardMutation };
}

export const useDeleteProjectDashboard = () => {
  const queryClient = useQueryClient();

  const deleteProjectDashboardMutation = useMutation({
    mutationFn: (dashboardId: number) => {
      const endPoint = Resources.PROJECT_DASHBOARD_BY_ID.replace(
        '<int:pk>',
        dashboardId.toString(),
      );
      return ApiService.delete(endPoint).then((res) => res.data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.projectDashboards,
      });
    },
  });

  return {
    deleteProjectDashboardMutation,
  };
};

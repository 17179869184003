import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

const analysisIdAtom = atom<number | 'new' | undefined>(undefined);
analysisIdAtom.debugLabel = 'analysisIdAtom';

const variantIdAtom = atom<number | null>(null);
variantIdAtom.debugLabel = 'variantIdAtom';

type VariantViewMode = 'VIEW' | 'EDIT' | 'CREATE';
const variantFlyoutAtom = atom<{ isOpen: boolean; viewMode: VariantViewMode }>({
  isOpen: false,
  viewMode: 'VIEW',
});
variantFlyoutAtom.debugLabel = 'variantFlyoutAtom';

export const useAnalysisFlyoutDetails = () => {
  const [analysisDetailId, setAnalysisDetailId] = useAtom(analysisIdAtom);

  const closeAnalysisModal = useCallback(() => {
    setAnalysisDetailId(undefined);
  }, [setAnalysisDetailId]);

  return {
    analysisDetailId,
    closeAnalysisModal,
    setAnalysisDetailId,
  };
};

export const useSelectedVariantId = () => {
  const [selectedVariantId, setSelectedVariantId] = useAtom(variantIdAtom);

  return {
    selectedVariantId,
    setSelectedVariantId,
  };
};

export const useVariantFlyout = () => {
  const [variantFlyoutState, setVariantFlyoutState] = useAtom(variantFlyoutAtom);

  const openVariantFlyout = useCallback(
    (viewMode: VariantViewMode) => {
      setVariantFlyoutState({ isOpen: true, viewMode });
    },
    [setVariantFlyoutState],
  );

  const closeVariantFlyout = useCallback(() => {
    setVariantFlyoutState({ isOpen: false, viewMode: 'VIEW' });
  }, [setVariantFlyoutState]);

  return {
    ...variantFlyoutState,
    openVariantFlyout,
    closeVariantFlyout,
  };
};

const openAddCompareModalAtom = atom<'PROJECTS' | 'COMPONENTS' | ''>('');

export const useOpenCompareModalState = () => {
  const [compareModalOpen, setCompareModalOpen] = useAtom(openAddCompareModalAtom);

  return { compareModalOpen, setCompareModalOpen };
};

const variantSettingsFlyoutAtom = atom<{
  isOpen: boolean;
  settings: Record<string, boolean>;
}>({
  isOpen: false,
  settings: {
    isPlaygroundColumnEnabled: true,
  },
});
variantSettingsFlyoutAtom.debugLabel = 'variantSettingFlyoutAtom';

export const useVariantSettingsFlyoutDetails = () => {
  const [variantSettings, setVariantSettings] = useAtom(variantSettingsFlyoutAtom);

  const openVariantSettingsModal = useCallback(() => {
    setVariantSettings({ settings: variantSettings.settings, isOpen: true });
  }, [setVariantSettings, variantSettings]);

  const closeVariantSettingsModal = useCallback(() => {
    setVariantSettings({ settings: variantSettings.settings, isOpen: false });
  }, [setVariantSettings, variantSettings]);

  const updateVariantSettings = useCallback(
    (key: string, value: boolean) => {
      setVariantSettings({
        isOpen: variantSettings.isOpen,
        settings: { ...variantSettings.settings, [key]: value },
      });
    },
    [setVariantSettings, variantSettings],
  );

  return {
    ...variantSettings,
    openVariantSettingsModal,
    closeVariantSettingsModal,
    updateVariantSettings,
  };
};

import {
  Box,
  Checkbox,
  CircularProgress,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Modal } from 'components/Modal/Modal';
import { useCompanyUsers } from 'features/ManageUsers/hooks/companyUsers';
import { FlyoutDialogTitleIcon } from 'components/FlyoutDialog/FlyoutDialogTitleIcon';
import { faMagnifyingGlass, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { DebouncedTextField } from 'components/DebouncedTextField/DebouncedTextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserFullnameOrEmail, isExternalUser } from 'utils/helpers';
import { sortBy } from 'lodash-es';
import { useFormikContext } from 'formik';
import { EditingProjectDashboard } from 'features/project-dashboard/helpers';
import { useCurrentCompany } from 'features/Company/hooks/useCurrentCompany';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';

type ProjectDashboardMembersSectionManageProps = {
  onClose: () => void;
};

const positionKey = 'ProjectDashboardMembersSectionManage';

const defaultPosition = { x: 28, y: -24 };
export function ProjectDashboardMembersSectionManage({
  onClose,
}: ProjectDashboardMembersSectionManageProps) {
  const { values, setFieldValue } = useFormikContext<EditingProjectDashboard>();
  const { currentCompany } = useCurrentCompany();
  const { currentUser } = useCurrentUser();

  const users = values.users;

  const { companyUsersQuery } = useCompanyUsers();
  const position = useMemo(() => {
    return sessionStorage.getItem(positionKey)
      ? JSON.parse(sessionStorage.getItem(positionKey) as string)
      : defaultPosition;
  }, []);
  const debouncedPosition = useDebouncedCallback((p) => {
    sessionStorage.setItem(positionKey, JSON.stringify(p));
  }, 1000);
  const [search, setSearch] = useState('');
  const mappedUsers =
    companyUsersQuery.data
      ?.filter(
        (u) =>
          (values.type === 'EXTERNAL' ||
            (values.type === 'INTERNAL' && !isExternalUser(currentCompany, u.email))) &&
          currentUser &&
          u.id !== currentUser.id,
      )
      .map((u) => ({ ...u, label: getUserFullnameOrEmail(u) })) || [];
  const filteredUsers = sortBy(
    !search
      ? mappedUsers
      : mappedUsers.filter((u) => u.label.toLowerCase().startsWith(search.toLowerCase())),
    (u) => u.label.toLowerCase(),
  );
  return (
    <Modal
      defaultPosition={position}
      onDrag={({ x, y }) => {
        debouncedPosition({ x, y });
      }}
      onClose={onClose}
      sx={{ position: 'fixed', width: '400px' }}
    >
      <Box sx={{ px: 2, pt: 1, pb: 2 }}>
        <Box>
          <FlyoutDialogTitleIcon size="sm" icon={faUserPlus} />
          <Typography variant="h4" component="span">
            Add members
          </Typography>
        </Box>
        <DebouncedTextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          placeholder="Quick search"
          sx={{ mt: 1, width: '100%' }}
        />
        <Stack
          sx={{
            maxHeight: '300px',
            overflow: 'auto',
            mt: 1,
            border: '1px solid',
            borderColor: 'grey.200',
            borderRadius: '8px',
          }}
        >
          {companyUsersQuery.isLoading || !currentUser ? (
            <CircularProgress sx={{ m: 'auto', my: 8 }} />
          ) : filteredUsers.length === 0 ? (
            <Typography sx={{ p: 2 }}>No available users</Typography>
          ) : (
            filteredUsers.map((u) => {
              const isChecked = !!users.find((m) => m.id === u.id);
              return (
                <Stack
                  key={u.id}
                  onChange={() => {
                    let updated;
                    if (isChecked) {
                      updated = users.filter((m) => m.id !== u.id);
                    } else {
                      updated = [...users, u];
                    }
                    setFieldValue('users', updated);
                  }}
                  sx={{ flexDirection: 'row', alignItems: 'center' }}
                >
                  <Checkbox checked={isChecked} />
                  <Typography>{u.label}</Typography>
                </Stack>
              );
            })
          )}
        </Stack>
      </Box>
    </Modal>
  );
}

import { Box, Chip, Divider, Popover, Typography } from '@mui/material';
import { NavBarButton } from 'components/NavBarButton/NavBarButton';
import { PrivateURL } from 'Urls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/pro-regular-svg-icons';
import { useIsLocationActive } from 'hooks/useIsLocationActive';
import { ReactNode, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProjectDashboardVisibility } from 'types/Dashboard';
import { SxProps } from '@mui/system/styleFunctionSx';
import { joinSx } from 'utils/helpers';
import { getProjectDashboardUrl } from 'features/project-dashboard/helpers';
import { useForesiteFlyout } from 'features/Foresite/hooks/ui';
import { useRecentProjectDashboard } from 'features/project-dashboard/hooks/useRecentProjectDashboard';
import usePermissions from 'features/Auth/hook/usePermissions';
import { useProjectDashboards } from 'features/project-dashboard/hooks/project-dashboard';

export function NavBarProjectDashboardButton() {
  const { recentsProjectDashboard } = useRecentProjectDashboard();
  const { isExactLocationActive } = useIsLocationActive();
  const { setProjectDashboardDetailsId } = useForesiteFlyout();
  const popoverAnchor = useRef<HTMLDivElement | null>(null);
  const [openedPopover, setOpenedPopover] = useState(false);
  const { hasPerm, Permissions } = usePermissions();
  const { projectDashboardsQuery } = useProjectDashboards();

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const id = 'navbar-dashboard-mouse-over-popover';
  const hasRecentProjectDashboard = recentsProjectDashboard.length > 0;

  if (
    !(
      hasPerm(Permissions.PORTFOLIO_ACCESS) ||
      hasPerm(Permissions.ADD_PROJECT_DASHBOARD) ||
      (projectDashboardsQuery.data && projectDashboardsQuery.data.length > 0)
    )
  )
    return null;

  return (
    <>
      <Box
        ref={popoverAnchor}
        aria-owns={id}
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
      >
        <NavBarButton
          icon={<FontAwesomeIcon icon={faBriefcase} />}
          isActive={
            isExactLocationActive(PrivateURL.PROJECT_DASHBOARD_LIST) ||
            isExactLocationActive(PrivateURL.PROJECT_DASHBOARD_DETAIL)
          }
        >
          Dashboards
        </NavBarButton>
      </Box>
      <Popover
        id={id}
        sx={{ pointerEvents: 'none' }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={popoverLeave}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave,
          sx: {
            pointerEvents: 'auto',
            minWidth: 220,
            overflow: 'auto',
          },
        }}
      >
        {hasRecentProjectDashboard ? (
          <>
            <Typography
              variant="textDefaultSemiBold"
              sx={{ color: 'grey.500', p: 1.5, display: 'block' }}
            >
              Recent
            </Typography>
            {recentsProjectDashboard.map((recent) => {
              return (
                <NavBardPopoverButton
                  key={recent.id}
                  onClick={popoverLeave}
                  to={getProjectDashboardUrl(recent)}
                >
                  <Typography variant="textDefaultSemiBold">{recent.name}</Typography>
                  <DashboardVisibilityChip type={recent.type} />
                </NavBardPopoverButton>
              );
            })}
          </>
        ) : null}
        {recentsProjectDashboard.length > 0 ? <Divider sx={{ my: 1 }} /> : null}
        <NavBardPopoverButton
          to={PrivateURL.PROJECT_DASHBOARD_LIST}
          onClick={popoverLeave}
        >
          <Typography variant="textDefaultSemiBold">View all dashboards</Typography>
        </NavBardPopoverButton>
        <NavBardPopoverButton
          to={PrivateURL.PROJECT_DASHBOARD_LIST}
          onClick={() => {
            setProjectDashboardDetailsId('new');
            popoverLeave();
          }}
        >
          <Typography variant="textDefaultSemiBold">Create dashboard</Typography>
        </NavBardPopoverButton>
      </Popover>
    </>
  );
}

function NavBardPopoverButton({
  onClick,
  to,
  sx,
  children,
}: {
  onClick: () => void;
  to?: string;
  sx?: SxProps;
  children: ReactNode;
}) {
  return (
    <Box
      {...(to
        ? {
            component: Link,
            to,
          }
        : {})}
      sx={joinSx(
        {
          px: 1.5,
          py: 1,
          display: 'flex',
          gap: 1,
          '&:hover': { backgroundColor: 'blue.50' },
        },
        sx,
      )}
      onClick={onClick}
    >
      {children}
    </Box>
  );
}

function DashboardVisibilityChip({ type }: { type: ProjectDashboardVisibility }) {
  return (
    <Chip
      label={type === 'INTERNAL' ? 'Internal' : 'External'}
      sx={{
        color: 'primary.main',
        bgcolor: 'blue.50',
      }}
    />
  );
}

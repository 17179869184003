import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'utils/reactQuery';
import { Resources } from 'api/Resources';
import { ApiService } from 'api/ApiService';
import { DashboardPermission, ProjectDashboard } from 'types/Dashboard';
import { User } from 'types/User';

export function useDashboardPermissions() {
  const dashboardPermissionsQuery = useQuery({
    queryKey: queryKeys.dashboardPermissions,
    queryFn: ({ signal }) => {
      const endPoint = Resources.DASHBOARD_PERMISSIONS;
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as DashboardPermission[],
      );
    },
    refetchOnWindowFocus: false,
  });
  return { dashboardPermissionsQuery };
}

export function hasEditDashboardSettingPermission({
  user,
  dashboard,
}: {
  user: User | null;
  dashboard: ProjectDashboard | undefined;
}) {
  if (!user || !dashboard) return false;

  const membership = dashboard.memberships?.find((m) => m.user_id === user.id);
  return !!membership?.permissions.find((p) => p.codename === 'edit_dashboard_settings');
}

export function hasManageDashboardCardPermission({
  user,
  dashboard,
}: {
  user: User | null;
  dashboard: ProjectDashboard | undefined;
}) {
  if (!user || !dashboard) return false;

  const membership = dashboard.memberships?.find((m) => m.user_id === user.id);
  return !!membership?.permissions.find((p) => p.codename === 'edit_dashboard_cards');
}

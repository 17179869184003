import { Button, ButtonProps } from '@mui/material';
import { joinSx } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

export function CreateProjectDashboardButton({
  sx,
  onClick,
}: Pick<ButtonProps, 'sx' | 'onClick'>) {
  return (
    <Button
      sx={joinSx({ px: 2 }, sx)}
      variant="blueGhost"
      startIcon={<FontAwesomeIcon icon={faPlus} />}
      onClick={onClick}
    >
      Create dashboard
    </Button>
  );
}

import { ownerCostCodes } from 'utils/owner-cost';
import {
  ApplyToLumpSum,
  ApplyToPercentage,
  Markup,
  MarkupType,
  MarkupWithSubtotal,
  PartialMarkupForSubtotalCalculation,
} from './Markup';
import { AsyncResource } from './AsyncTask';
import { CostGroup, FormatMapper } from './CostGroup';

type Subtotal = {
  prev: number | null;
  cost: number;
  variance: number | null;
  variance_percent: number | null;
  unit: number;
  total: number;
};

type SimpleTotal = {
  cost: number;
  // Field with the full component cost regardless if we are filtering by component
  full_cost: number | null;
  prev: number;
};

export type Subtotals = {
  [key: number]: {
    [key: number]: Subtotal;
    _total: SimpleTotal;
    full_total: number;
  };
  _total: { [key: number]: Subtotal; _total: SimpleTotal };
};

export type ComponentSubtotal = {
  id: number;
  subtotal: number;
  non_iterative_subtotal: number;
  ni_variance: number;
  ni_variance_percent: number;
  variance: number;
  variance_percent: number;
};

export type OwnerCostCode = (typeof ownerCostCodes)[number]['code'];
export type OwnerVariance = {
  id: number;
  value: number;
};
export type OwnerCost = {
  id: number;
  description: string;
  code: OwnerCostCode;
  cost: number;
  order?: number;
  type: MarkupType;
  value?: number;
  applied_to: ApplyToLumpSum[];
  milestone: number;
  variance?: OwnerVariance[];
};

export type FormatDefinitionValue = {
  code: string;
  name: string;
  component_values: Record<string, number>;
  total: number;
  rows: (number | { value: number })[];
  template_rows?: { value: number; coordinate: string }[];
};

type ParsedLineItem = {
  code: string;
  description: string;
  component: string;
  amount: number;
};

export type ParsedLineItemField = keyof ParsedLineItem;

export type ErrorFields = Record<ParsedLineItemField, string>;

export type ParsingError = {
  type?: string;
  name: string;
};
export const GlobalErrorTypeString = [
  'missing_headers',
  'empty_formats',
  'sheet_parser_error',
] as const;
export const columnTypeKeys = ['amount', 'description', 'component', 'formats'];
export type ColumnType = (typeof columnTypeKeys)[number];
export type GlobalErrorType = (typeof GlobalErrorTypeString)[number];
export type PartialHeadersRow = { row: number; missing_columns: ColumnType[] };
export type TypedError = ParsingError & { type: GlobalErrorType };
export type MissingHeadersError = TypedError & {
  required_columns: ColumnType[];
  partial_headers_row: PartialHeadersRow[];
};

export type GlobalParsingError = MissingHeadersError;

export type RowParsingError = ParsingError &
  Partial<
    {
      row: number;
      template_row: { value: number; coordinate: string } | null;
      error_fields: ErrorFields;
    } & ParsedLineItem
  >;

export type FormatDefinition = {
  id: string;
  name: string;
  values: Record<number, FormatDefinitionValue>;
  component_markups: Record<number, FormatDefinitionValue>;
  overall_markups: object; // ignored for now
  bid_packages: object; // ignored for now
  invalid_rows: RowParsingError[];
  errors: GlobalParsingError[];
  warnings: ParsingError[];
  markups_total: number;
  total: number;
  invalid_rows_total: number;
};

export type TemplateMetadata = {
  columns: { code: number; description: number; components: Record<string, number> };
  formats: [{ headers_row: number }];
};

export type ParsedOutput = {
  formats: Record<number, FormatDefinition>;
  errors: ParsingError[];
  warnings: ParsingError[];
  info: string[];
  column_mapping: Record<string, string>;
  template_metadata: TemplateMetadata;
  header_coordinates: Record<string, { row: number; column: number }>;
};

export type MilestoneEstimateUploadStatus =
  | 'INITIALIZED'
  | 'PARSING'
  | 'PARSED'
  | 'ERROR'
  | 'SAVED';

// TODO: improve this type
export type ParserConfig = {
  columns: Record<string, string>;
  components: string[];
  is_template: boolean;
};

export type ExcelSheet = { name: string; rows: Record<string, string>[] };
export type ExcelWorkbook = ExcelSheet[];

export type MilestoneEstimateUpload = AsyncResource & {
  filename: string;
  id: number;
  milestone_id: number;
  mapper: number;
  parser_config: ParserConfig | null;
  parser_output: ParsedOutput | null;
  status: MilestoneEstimateUploadStatus;
  converted_estimate_file: ExcelWorkbook;
};

export type GenericMapper = {
  id: number;
  integration: 'BECK_DESTINI' | 'SAGE' | 'WINEST' | 'CONCNTRIC' | 'COSTOS';
  base_sheet_name: string;
  component_name: string;
  description: string;
  total_for_line_item: string;
  format_mappers: FormatMapper[];
  quantity: string;
  unit_cost: string;
  unit_of_measure: string;
};

export type GenericMapperKeys = keyof GenericMapper;

export const MapperKeyToColumnKey = {
  component_name: 'component',
  total_for_line_item: 'amount',
  format_mappers: 'formats',
} as Record<GenericMapperKeys, ColumnType>;

export const estimateTemplateType = {
  excel: 'excel',
  beckDestini: 'beckDestini',
  winestxls: 'winestxls',
  sage: 'sage',
  concntric: 'concntric',
  costos: 'costos',
} as const;

export type TemplateType = keyof typeof estimateTemplateType;

export type EstimateMarkup = Markup & {
  related_object_type: 'designmilestone';
  applied_to: ApplyToLumpSum[] | ApplyToPercentage[];
  components_subtotal: ComponentSubtotal[];
};

export type LumpSumEstimateMarkup = EstimateMarkup & {
  type: 'LUMP_SUM';
  applied_to: ApplyToLumpSum[];
};

export type PercentageEstimateMarkup = EstimateMarkup & {
  type: 'PERCENTAGE';
  applied_to: ApplyToPercentage[];
};

export type EstimateMarkupWithSubtotal = MarkupWithSubtotal &
  EstimateMarkup & {
    componentsSubtotal: ComponentSubtotal[];
  };

export type PartialEstimateMarkupForSubtotalCalculation =
  PartialMarkupForSubtotalCalculation &
    Pick<EstimateMarkup, 'applied_to'> & {
      appliedToComponents: ApplyToLumpSum[];
      order: number | undefined;
    };

type CostGroupInfo = {
  code: string;
  original_code: string | null;
};

export type LineItem = {
  id: number;
  description: string;
  codes: CostGroup[];
  cost_groups_info: CostGroupInfo[];
  measurement_unit: string | null;
  quantity: number | null;
  unit_cost: number | null;
  total: number;
  row_number: number;
  source_for_item: boolean;
  component: number;
  cost_groups: number[];
  milestone: number;
};

export type TotalsSummary = {
  has_estimate: boolean;
  total: number;
  components_totals: {
    id: number;
    name: string;
    subtotal: number;
  }[];
};

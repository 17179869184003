export const Permissions = {
  // Overview Module
  OVERVIEW_ACCESS: 'overview_access',
  CHANGE_SETTING: 'change_setting',
  OVERVIEW_WIDGET_CALIBRATE: 'overview_widget_calibrate',
  OVERVIEW_WIDGET_COMPONENT: 'overview_widget_component',
  OVERVIEW_WIDGET_COST: 'overview_widget_cost',
  OVERVIEW_WIDGET_ESTIMATE: 'overview_widget_estimate',
  OVERVIEW_WIDGET_ISSUE: 'overview_widget_issue',
  PROJECT_SETTINGS: 'project_settings',

  // Vision Module
  VISION_ACCESS: 'vision_access',
  VISION_WIDGET_CALIBRATE: 'vision_widget_calibrate',
  VISION_WIDGET_PROGRAM: 'vision_widget_program',
  VISION_WIDGET_COST: 'vision_widget_cost',

  // Foresite Module
  FORESITE_ACCESS: 'foresite_access',
  // Project VO Items
  APPROVE_SCENARIO_ITEM: 'approve_scenarioitem',
  EDIT_OWN_SCENARIO_ITEM: 'edit_own_scenarioitem',
  EDIT_ANY_SCENARIO_ITEM: 'edit_any_scenarioitem',
  DELETE_SCENARIO_ITEM: 'delete_scenarioitem',
  ADD_SCENARIO_ITEM: 'add_scenarioitem',
  REVERT_SCENARIOITEM: 'revert_scenarioitem',
  SCENARIO_ITEM_CLONE: 'scenario_item_clone',
  // Project VO Items' Scopes (SubItems)
  ADD_SCENARIO_SUB_ITEM: 'add_scenariosubitem',
  CHANGE_SCENARIO_SUB_ITEM: 'change_scenariosubitem',
  DELETE_SCENARIO_SUB_ITEM: 'delete_scenariosubitem',
  // Project VO Items' Markups
  ADD_ITEM_MARKUP: 'add_item_markup',
  VIEW_ITEM_MARKUP: 'view_item_markup',
  EDIT_ITEM_MARKUP: 'edit_item_markup',
  DELETE_ITEM_MARKUP: 'delete_item_markup',
  // Project Scenarios
  ADD_SCENARIO: 'add_scenario',
  APPROVE_SCENARIO: 'approve_scenario',
  CHANGE_SCENARIO: 'change_scenario',
  DELETE_SCENARIO: 'delete_scenario',
  // Items' issues
  // We will use ISSUE_ACCESS until further requirements
  // ADD_ISSUE: 'add_issue',
  // CHANGE_ISSUE: 'change_issue',
  // DELETE_ISSUE: 'delete_issue',
  // VIEW_ISSUE: 'view_issue',
  FORESITE_EXPORT: 'foresite_export',

  // TVD Module
  TVD_ACCESS: 'tvd_access',
  CHANGE_TVD_DIMENSION_MEMBER_PRAMS: 'change_dimensionmemberparams',

  // Calibrate Module
  CALIBRATE_ACCESS: 'calibrate_access',
  CALIBRATE_EXPORT: 'calibrate_export',

  // Procurement Module
  PROCUREMENT_ACCESS: 'procurement_access',

  // Risk Module
  RISK_ACCESS: 'risk_access',
  RISK_EXPORT: 'risk_export',

  // Context Module
  CONTEXT_ACCESS: 'context_access',

  // Issue Module
  ISSUE_ACCESS: 'issue_access',
  ISSUE_EXPORT: 'issue_export',

  // Reporting Module
  REPORTING_ACCESS: 'reporting_access',

  // Project detail Module
  CHANGE_PROJECT: 'change_project',
  DELETE_PROJECT: 'delete_project',
  VIEW_ALL_PROJECTS: 'view_all_projects',
  ADD_PROJECT_GROUP: 'add_projectgroup',

  // Timeline Module
  TIMELINE_ACCESS: 'timeline_access',
  ADD_DESIGNMILESTONE: 'add_designmilestone',
  CHANGE_DESIGNMILESTONE: 'change_designmilestone',
  DELETE_DESIGNMILESTONE: 'delete_designmilestone',
  ADD_TIMELINEPHASE: 'add_timelinephase',
  CHANGE_TIMELINEPHASE: 'change_timelinephase',
  DELETE_TIMELINEPHASE: 'delete_timelinephase',
  ADD_TIMELINEACTIVITY: 'add_timelineactivity',
  CHANGE_TIMELINEACTIVITY: 'change_timelineactivity',
  DELETE_TIMELINEACTIVITY: 'delete_timelineactivity',
  ADD_TIMELINE_CONTRACT_EVENT: 'add_timelinecontractevent',
  CHANGE_TIMELINE_CONTRACT_EVENT: 'change_timelinecontractevent',
  DELETE_TIMELINE_CONTRACT_EVENT: 'delete_timelinecontractevent',

  // Project's Components Modules
  COMPONENT_ACCESS: 'component_access',
  ADD_COMPONENT: 'add_component',
  CHANGE_COMPONENT: 'change_component',
  DELETE_COMPONENT: 'delete_component',

  // Estimate Modules
  ESTIMATE_ACCESS: 'estimate_access',
  DOWNLOAD_ESTIMATE: 'download_estimate',
  UPLOAD_ESTIMATE: 'upload_estimate',

  // Program/Area Module
  PROGRAM_ACCESS: 'program_access',

  // Ideas Library Module
  IDEA_ACCESS: 'idea_access',
  ADD_IDEA: 'add_idea',
  CHANGE_IDEA: 'change_idea',
  DELETE_IDEA: 'delete_idea',

  // Portfolio module
  PORTFOLIO_ACCESS: 'portfolio_access',
  PORTFOLIO_EXPORT: 'portfolio_export',
  ADD_PROJECT_DASHBOARD: 'add_projectsdashboard',

  // Invite Members Modules
  INVITE_MEMBERS_TO_PROJECT: 'invite_members_to_project',

  ADD_PROJECT: 'add_project',

  // Company
  CHANGE_COMPANY: 'change_company',

  // Users
  VIEW_USERS: 'view_usertenantpermissions',
  DELETE_USER: 'delete_usertenantpermissions',

  // Custom codes
  CUSTOM_CODES_ACCESS: 'custom_codes_access',

  // Account administration: users, roles and access scopes/business units
  ACCOUNT_ADMINISTRATION_ACCESS: 'account_administration_access',

  // Public API Keys
  ADD_APIKEY: 'add_apikey',
  CHANGE_APIKEY: 'change_apikey',
  VIEW_APIKEY: 'view_apikey',
  // DELETE_APIKEY: 'delete_apikey', // Currently, we are not deleting apikeys, we are revoking them (a change)

  // Markup Templates
  MARKUP_TEMPLATES_ACCESS: 'markup_templates_access',
  ADD_MARKUP_TEMPLATES: 'add_markuptemplate',
  CHANGE_MARKUP_TEMPLATES: 'change_markuptemplate',
  DELETE_MARKUP_TEMPLATES: 'delete_markuptemplate',
  VIEW_MARKUP_TEMPLATES: 'view_markuptemplate',
  VIEW_MARKUP_TEMPLATES_GROUP: 'view_markuptemplategroup',
  ADD_MARKUP_TEMPLATES_GROUP: 'add_markuptemplategroup',
  CHANGE_MARKUP_TEMPLATES_GROUP: 'change_markuptemplategroup',
  DELETE_MARKUP_TEMPLATES_GROUP: 'delete_markuptemplategroup',

  // Concept Lab
  CONCEPT_LAB_ACCESS: 'concept_lab_access',
  ADD_CONCEPT_LAB_ANALYSIS: 'add_analysis',
  CHANGE_CONCEPT_LAB_ANALYSIS: 'change_analysis',
  DELETE_CONCEPT_LAB_ANALYSIS: 'delete_analysis',
  VIEW_CONCEPT_LAB_ANALYSIS: 'view_analysis',
} as const;

export type PermissionType = (typeof Permissions)[keyof typeof Permissions];

export const useFeatureFlags = () => {
  const isNotificationEnabled =
    import.meta.env.VITE_FEATUREFLAG_NOTIFICATION?.toLowerCase() === 'true';
  const isCustomNormalizationEnabled =
    import.meta.env.VITE_FEATUREFLAG_CUSTOM_NORMALIZATION?.toLowerCase() === 'true';
  const isForeSiteChartZoomEnabled =
    import.meta.env.VITE_FEATUREFLAG_FORESITE_CHART_ZOOM?.toLowerCase() === 'true';
  const isCustomCodesEnabled =
    import.meta.env.VITE_FEATUREFLAG_CUSTOM_CODES?.toLowerCase() === 'true';
  const isBidPackageDetailsEnabled =
    import.meta.env.VITE_FEATUREFLAG_PROCUREMENT_BID_PACKAGE_DETAILS_ENABLED?.toLowerCase() ===
    'true';
  const isReportingEnabled =
    import.meta.env.VITE_FEATUREFLAG_REPORTING?.toLowerCase() === 'true';
  const isPWAEnabled = import.meta.env.VITE_FEATUREFLAG_PWA?.toLowerCase() === 'true';

  const isTVDMFReportEnabled =
    import.meta.env.VITE_FEATUREFLAG_TVD_MF_REPORT_ENABLED?.toLowerCase() === 'true';
  const isContextEnabled =
    import.meta.env.VITE_FEATUREFLAG_CONTEXT?.toLowerCase() === 'true';
  const isIssueManagementEnabled =
    import.meta.env.VITE_FEATUREFLAG_ISSUE_MANAGEMENT?.toLowerCase() === 'true';

  const isDashboardEnabled =
    import.meta.env.VITE_FEATUREFLAG_DASHBOARD?.toLowerCase() === 'true';

  return {
    isNotificationEnabled,
    isCustomNormalizationEnabled,
    isForeSiteChartZoomEnabled,
    isCustomCodesEnabled,
    isBidPackageDetailsEnabled,
    isPWAEnabled,
    isReportingEnabled,
    isTVDMFReportEnabled,
    isContextEnabled,
    isIssueManagementEnabled,
    isDashboardEnabled,
  };
};

export type FeatureFlagsKeys = keyof ReturnType<typeof useFeatureFlags>;
